/**
 * 环境配置
 * 开发环境
 * 测试环境
 * 线上环境
 */

// 当前环境配置
//const env = import.meta.env.MODE || "prod";

const EnvConfig = {
  development: {
    baseApi: "/api",
    mockApi:
      "https://www.fastmock.site/mock/ddc9fba038b39a595af3744711133169/api",
  },
  test: {
    baseApi: "/api",
    mockApi:
      "https://www.fastmock.site/mock/ddc9fba038b39a595af3744711133169/api",
  },
  prod: {
    // 打包的时候用这个
    baseApi: "/api",
    // 本地运行用这个
    //baseApi: "http://imageconvert.test:7888/api",
    mockApi: "http://amz-adv.test:8888",
  },
};

export default {
  // env,
  mock: false,
  ...EnvConfig["prod"],
};
