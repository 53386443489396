<template>
  <div id="app"><router-view></router-view></div>
</template>

<style>
html,
body,
#app {
  margin: 0;
  padding: 0;
  height: 100%;
}
.el-header {
  background-color: #363636;
  display: flex;
  justify-content: space-between;
}
</style>
