/**
 * 整个项目api的管理
 */

import request from "./request";

export default {
  userLogin(params) {
    return request({
      url: "/login",
      method: "post",
      data: params,
      mock: false,
    });
  },

  getCurrentUser(params) {
    return request({
      url: "/common/current_user",
      method: "get",
      mock: false,
    });
  },
  uploadImages(params) {
    return request({
      url: "/upload_image",
      method: "post",
      data: params,
      mock: false,
    });
  },
  addImage(params) {
    return request({
      url: "/add_image",
      method: "post",
      data: params,
      mock: false,
    });
  },
  getImageList(params) {
    return request({
      url: "/image_list",
      method: "get",
      data: params,
      mock: false,
    });
  },
  deleteImages(params) {
    return request({
      url: "/delete_image",
      method: "post",
      data: params,
      mock: false,
    });
  },
};
