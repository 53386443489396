import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "./assets/less/index.less";
import store from "./store/index.js";

import { useRoute } from "vue-router";

// 引入api请求
// import "./api/mock.js";
import api from "./api/api";

// import "./api/mock.js"

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
function checkRouter(path) {
  let hasCheck = router
    .getRoutes()
    .filter((route) => route.path == path).length;
  if (hasCheck) {
    return true;
  } else {
    return false;
  }
}
function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

// 添加路由守卫
router.beforeEach((to, from, next) => {
  store.commit("getToken");
  var token = store.state.token;
  if (!token) {
    var query = getQueryVariable("token");
    token = query;
    if (token) {
      token = token.replace("Bearer ", "");
      token = token.replace("Bearer%20", "");
      store.commit("setToken", token);
    }
  }

  if (!token && to.name !== "login") {
    next({ name: "login" });
  } else if (!checkRouter(to.path)) {
    next({ name: "index" });
  } else {
    next();
  }
});

app.use(router);
app.use(ElementPlus);
app.use(store);

// 全局挂载api
app.config.globalProperties.$api = api;

app.mount("#app");
