import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";

const routes = [
  {
    path: "/",

    component: () => import("../views/Main.vue"),
    redirect: "/home",
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("../views/index/Index.vue"),
      },
      {
        path: "/add_image",
        name: "add_image",
        component: () => import("../views/index/add_image.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
