import axios from "axios";
import config from "../config";
import { ElMessage } from "element-plus";
const NETWORK_ERROR = "网络异常，请稍后重试";

import router from "../router/index";
import store from "../store/index";

// 创建axios实例
const service = axios.create({
  baseURL: config.baseApi,
  timeout: 40000, // 请求超时时间
});

// 在请求之前做什么

service.interceptors.request.use((req) => {
  console.log("req", req["url"]);
  let url = req["url"];
  if (url == "/upload_image") {
    req.headers["Content-Type"] = "multipart/form-data";
  }
  // 添加一下token,jwt
  store.commit("getToken");
  const token = store.state.token;

  if (token) {
    req.headers["Authorization"] = "Bearer " + token;
  }
  // console.log(token);

  return req;
});

// 返回结果之前做什么
service.interceptors.response.use(
  (res) => {
    const { data, code, message, status } = res.data;

    if (code == 200) {
      return res.data;
    } else if (code == 401) {
      console.log("进入401");
      ElMessage.error("token已经失效,请重新登录");
      // const router = useRouter();
      store.commit("clearToken");
      router.push({ path: "/login" });
      // // 未登录
      // // 跳转登录页面
      // return res;
    }

    return res.data;
    // else {
    //   ElMessage.error(message || NETWORK_ERROR);
    //   return Promise.reject(message || NETWORK_ERROR);
    // }

    // return data;
  },
  (error) => {
    let message = "请求超时，请稍后重试";
    if (error.response && error.response.data && error.response.data.message) {
      message = error.response.data.message || "请求超时，请稍后重试";
    }
    ElMessage.error(message);
    if (message == "Unauthorized") {
      store.commit("clearToken");
      router.push({ path: "/login" });
    }
    console.log("error", message);
    //store.commit("clearToken");
    //router.push({ path: "/login" });
    // console.log("error", error.response.data.message);
    return Promise.reject(error);
  }
);

// 封装和请求相关的函数
function request(options) {
  options.method = options.method || "get";
  if (options.method.toLowerCase() === "get") {
    options.params = options.data;
  }
  // let isMMock = config.mock;
  // if (typeof options.mock !== "undefined") {
  //   isMMock = options.mock;
  // }

  if (config.env === "prod") {
    service.defaults.baseURL = config.baseApi;
  } else {
    service.defaults.baseURL = config.baseApi;
  }

  return service(options);
}

export default request;
